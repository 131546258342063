import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Container = styled.div`
  & h3 {
    font-size: 24px;
    font-family: Onest-Bold;
    line-height: 24px;
    color: ${COLORS.black};
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const ProductListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;
export const Product = styled.div`
  background: ${COLORS.white};
  border-radius: 8px;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  padding: 16px;
  transition: 0.3s;
  & img {
    border-radius: 8px;
  }
  & h4 {
    font-size: 18px;
    line-height: 20px;
    font-family: Onest-Bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }
  &:active {
    transform: scale(0.9);
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DetailContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  & > p {
    font-size: 12px;
    color: ${COLORS.gray3};
    line-height: 16.8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const PriceContainer = styled.div`
  font-size: 16px;
  fontweight: 700;
  font-family: 'Onest-Bold';
`;
