import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

interface Props {
  type?: 'submit' | 'reset' | 'button';
  displayType?: string;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large' | 'full';
  height?: string;
  width?: string;
  borderType?: string;
  disabled?: boolean;
  fontType?: string;
  fontSize?: string;
}

export const ButtonComp = styled.button`
  display: flex;
  text-align: center;
  text-transform: capitalize;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: ${(props) => (props.size === 'full' ? '0 20px' : '')};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: ${(props) => props.fontType};
  font-family: Onest-Bold;
  line-height: 1.5rem;
  transition: 0.2s all ease-in-out;
  height: ${(props) => props.height || '45px'};
  &:hover {
    background-color: ${(props) =>
      props.displayType === 'purple' ? COLORS.blackLight2 : ''};

    border: ${(props: Props) =>
      props.displayType === 'white' ? `1px solid ${COLORS.black}` : ''};
  }
  &:active {
    transform: scale(0.95);
    background-color: ${(props) =>
      props.displayType === 'purple' ? COLORS.blackLight2 : ''};

    border: ${(props: Props) =>
      props.displayType === 'white' ? `1px solid ${COLORS.black}` : ''};
  }

  width: ${(props: Props) =>
    props.width ||
    (props.size == 'medium'
      ? '200px'
      : props.size == 'full'
      ? '100%'
      : '100px')};

  background: ${(props: Props) =>
    props.displayType == 'purple'
      ? COLORS.black
      : props.displayType == 'transparent'
      ? 'transparent'
      : COLORS.white};

  color: ${(props: Props) =>
    props.displayType == 'purple' ? COLORS.white : COLORS.black};

  border: ${(props: Props) =>
    props.displayType == 'purple'
      ? 'none'
      : props.displayType == 'transparent'
      ? 'none'
      : `1px solid ${COLORS.gray1}`};

  border-radius: ${(props: Props) =>
    props.borderType == 'no-bottom' ? '8px 8px 0 0' : '8px'};
`;

export const DynamicButtonContainer = styled.button`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props: Props) =>
    props.displayType == 'purple'
      ? COLORS.black
      : props.displayType == 'transparent'
      ? 'transparent'
      : COLORS.white};

  color: ${(props: Props) =>
    props.displayType == 'purple' ? COLORS.white : COLORS.black};

  border: ${(props: Props) =>
    props.displayType == 'purple'
      ? 'none'
      : props.displayType == 'transparent'
      ? 'none'
      : `1px solid ${COLORS.black};`};

  padding: 0 10px;
  height: ${(props: Props) => props.height};
  width: ${(props: Props) => props.width};
  border-radius: 6px;
  font-size: ${(props: Props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props: Props) => props.fontType};
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.displayType === 'purple' ? COLORS.blackLight2 : ''};
    color: ${(props: Props) =>
      props.displayType === 'white' ? COLORS.black : ''};
    border: ${(props: Props) =>
      props.displayType === 'white' ? `1px solid ${COLORS.black}` : ''};
  }
  &:active {
    transform: scale(0.95);
    background-color: ${(props) =>
      props.displayType === 'purple' ? COLORS.blackLight2 : ''};
    color: ${(props: Props) =>
      props.displayType === 'white' ? COLORS.black : ''};
    border: ${(props: Props) =>
      props.displayType === 'white' ? `1px solid ${COLORS.black}` : ''};
  }
`;
