import axios from 'axios';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import { useRouter } from 'next/router';
import { ProductSearchInterface } from '../../Interface/ProductSearchInterface';
import { apiConstants } from '../../constants/apiConstants';
import { saveEventV3, trackEvent } from '../../utils/eventTracking';
import RecentSearchesMobile from '../RecentSearchesMobile/RecentSearchesMobile';
import RelatedSearchesMobile from '../RelatedSearchesMobile/RelatedSearchesMobile';
import { ResultsContainer, SearchContainer } from './MobileNavStyles';
import { SearchGiftCardsSection, SearchPageContainer } from './SearchPageStyle';

import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { H2Title } from '../../styles/pageStyles/commonStyle';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import GiftCard from '../PlatformCard/GiftCard';
import { getGiftCards
  //  giftCards 
  } from '../ProductSection/GiftCardsSection';

const SearchPage: React.FC = () => {
  const router = useRouter();
  const { width } = useWindowDimensions();

  const { messages } = useLocale();
  const { search_games_gift_msg, gift_cards_msg } = messages || {};

  const giftCards = getGiftCards(messages);

  const [searchedText, setSearchedText] = useState<string>(''); // this stores the string value that has been searched
  const [autocompleteResult, setAutocompleteResult] =
    useState<Array<ProductSearchInterface> | null>(null);

  const apiCall = (value: string) => {
    setSearchedText(value);
    if (value === '') {
      setAutocompleteResult(null);
      return;
    }
    const eventData = {
      event: 'search',
      search_term: value,
    };
    trackEvent('gtm_event', { eventData });
    debounceSearchEvent(value);
    axios
      .get(apiConstants.search + '/search', {
        withCredentials: true,
        params: {
          q: value,
        },
      })
      .then((response) => {
        setAutocompleteResult(response.data.data);
      })
      .catch((error) => {
        setAutocompleteResult(null);
      });
  };

  const searchEvent = useCallback((value: string) => {
    saveEventV3({
      category: 'header',
      action: 'input_text',
      label: 'search',
      properties: '',
      value: [value],
      from: router,
    });
  }, []);

  const debounceSearchEvent = React.useCallback(
    debounce(searchEvent, 1500),
    []
  );

  const debounceOnChange = React.useCallback(debounce(apiCall, 400), []);

  return (
    <SearchPageContainer>
      <SearchContainer
        onChange={(e) => {
          debounceOnChange(e.target.value);
        }}
        placeholder={search_games_gift_msg}
      />
      {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) && (
        <>
          <H2Title>{gift_cards_msg}</H2Title>
          <SearchGiftCardsSection>
            {giftCards.map((giftCard, index) => (
              <div key={index} style={{ flex: '1' }}>
                <GiftCard giftcard={giftCard} key={index} hideText={true} />
              </div>
            ))}
          </SearchGiftCardsSection>
        </>
      )}
      <ResultsContainer>
        {searchedText === '' ? (
          <RecentSearchesMobile />
        ) : (
          <RelatedSearchesMobile
            autocompleteResult={autocompleteResult}
            searchText={searchedText}
          />
        )}
      </ResultsContainer>
    </SearchPageContainer>
  );
};

export default SearchPage;
