import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { MessageKeyTypes } from '../../Interface/MessageKeyTypes';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { saveEventV3 } from '../../utils/eventTracking';
import Button from '../Button/Button';
import GiftCard from '../PlatformCard/GiftCard';
import {
  CardsSection,
  ProductSectionContainer,
  ProductSectionTitle,
  ProductSectionWrapper,
} from './ProductSectionNewStyle';

export interface GiftCardsInterface {
  displayName: string;
  title: string;
  iconUrl: string;
  webSize: {
    height: string;
    width: string;
  };
  imageUrl: string;
  url: string;
  query: any;
  gif: string;
}

export const getGiftCards = (
  messages: { [key in MessageKeyTypes]: string } | undefined
): Array<GiftCardsInterface> => {
  let { steam_cards_msg, xbox_cards_msg, psn_cards_msg, nintendo_cards_msg } =
    messages || {};
  return [
    {
      displayName: steam_cards_msg ?? '',
      title: 'Steam cards',
      iconUrl: '/icons/platform_icons/steam.svg',
      webSize: {
        height: '',
        width: '',
      },
      imageUrl:
        'https://static.driffle.com/media-gallery/prod/166228369254833900_steam-tile.webp',
      gif: '/tiles/giftcards/steam-gif.gif',
      url: '/store/steam-gift-cards',
      query: { 'productType[]': 'giftcard', 'platform[]': ['Steam'] },
    },
    {
      displayName: xbox_cards_msg ?? '',
      title: 'Xbox cards',
      iconUrl: '/icons/platform_icons/xbox.svg',
      webSize: {
        height: '',
        width: '',
      },
      imageUrl:
        'https://static.driffle.com/media-gallery/prod/166228370456832500_xbox-tile.webp',
      gif: '/tiles/giftcards/xbox-gif.gif',
      url: '/store/xbox-gift-cards',
      query: {
        'productType[]': 'giftcard',
        'platform[]': ['Xbox Live'],
      },
    },
    {
      displayName: psn_cards_msg ?? '',
      title: 'PSN cards',
      iconUrl: '/icons/platform_icons/psn.svg',
      webSize: {
        height: '',
        width: '',
      },
      imageUrl:
        'https://static.driffle.com/media-gallery/prod/166228367906293400_psn-tile.webp',
      gif: '/tiles/giftcards/psn-gif.gif',
      url: '/store/psn-gift-cards',
      query: {
        'productType[]': 'giftcard',
        'platform[]': ['PSN'],
      },
    },
    {
      displayName: nintendo_cards_msg ?? '',
      title: 'Nintendo cards',
      iconUrl: '/icons/platform_icons/nintendo.svg',
      webSize: {
        height: '',
        width: '',
      },
      imageUrl:
        'https://static.driffle.com/media-gallery/prod/166228366588728400_nintendo-tile.webp',
      gif: '/tiles/giftcards/nintendo-gif.gif',
      url: '/store/nintendo-gift-cards',
      query: { 'productType[]': 'giftcard', 'platform[]': 'Nintendo' },
    },
    // {
    //   title: 'Roblox cards',
    //   iconUrl: '/icons/platform_icons/other.svg',
    //   webSize: {
    //     height: '',
    //     width: '',
    //   },
    //   imageUrl: '/tiles/giftcards/nintendo-tile.png',
    //   url: '/store',
    //   query: { 'productType[]': 'giftcard', phrase: 'Roblox' },
    // },
  ];
};

// export const giftCards: Array<GiftCardsInterface> = [
//   {
//     title: 'Steam cards',
//     iconUrl: '/icons/platform_icons/steam.svg',
//     webSize: {
//       height: '',
//       width: '',
//     },
//     imageUrl:
//       'https://static.driffle.com/media-gallery/prod/166228369254833900_steam-tile.webp',
//     gif: '/tiles/giftcards/steam-gif.gif',
//     url: '/store/steam-giftcards',
//     query: { 'productType[]': 'giftcard', 'platform[]': ['Steam'] },
//   },
//   {
//     title: 'Xbox cards',
//     iconUrl: '/icons/platform_icons/xbox.svg',
//     webSize: {
//       height: '',
//       width: '',
//     },
//     imageUrl:
//       'https://static.driffle.com/media-gallery/prod/166228370456832500_xbox-tile.webp',
//     gif: '/tiles/giftcards/xbox-gif.gif',
//     url: '/store/xbox-giftcards',
//     query: {
//       'productType[]': 'giftcard',
//       'platform[]': ['Xbox Live'],
//     },
//   },
//   {
//     title: 'PSN cards',
//     iconUrl: '/icons/platform_icons/psn.svg',
//     webSize: {
//       height: '',
//       width: '',
//     },
//     imageUrl:
//       'https://static.driffle.com/media-gallery/prod/166228367906293400_psn-tile.webp',
//     gif: '/tiles/giftcards/psn-gif.gif',
//     url: '/store/psn-giftcards',
//     query: {
//       'productType[]': 'giftcard',
//       'platform[]': ['PSN'],
//     },
//   },
//   {
//     title: 'Nintendo cards',
//     iconUrl: '/icons/platform_icons/nintendo.svg',
//     webSize: {
//       height: '',
//       width: '',
//     },
//     imageUrl:
//       'https://static.driffle.com/media-gallery/prod/166228366588728400_nintendo-tile.webp',
//     gif: '/tiles/giftcards/nintendo-gif.gif',
//     url: '/store/nintendo-giftcards',
//     query: { 'productType[]': 'giftcard', 'platform[]': 'Nintendo' },
//   },
// {
//   title: 'Roblox cards',
//   iconUrl: '/icons/platform_icons/other.svg',
//   webSize: {
//     height: '',
//     width: '',
//   },
//   imageUrl: '/tiles/giftcards/nintendo-tile.png',
//   url: '/store',
//   query: { 'productType[]': 'giftcard', phrase: 'Roblox' },
// },
// ];

const GiftCardsSection = () => {
  const { width } = useWindowDimensions();
  const router = useRouter();

  const { messages } = useLocale();
  const { gift_cards_msg } = messages || {};

  const giftCards = getGiftCards(messages);

  const [currentCount, setCurrentCount] = useState<number>(4);

  const handleShowMoreClick = useCallback(() => {
    saveEventV3({
      category: 'collection_card',
      action: 'click',
      label: 'category_card',
      properties: 'view_all',
      value: [],
      from: router,
    });

    if (giftCards.length > currentCount) {
      if (width && width <= 768) {
        setCurrentCount(giftCards.length);
      } else if (width && width > 769 && width <= 1023) {
        setCurrentCount(giftCards.length);
      } else if (width && width > 1024 && width <= 1236) {
        setCurrentCount(giftCards.length);
      } else {
        setCurrentCount(giftCards.length);
      }
    }
  }, [giftCards.length, currentCount]);

  // const settings = {
  //   adaptiveHeight: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   swipeToSlide: true,
  //   dots: false,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePreviousArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         centerMode: false,
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         arrows: false,
  //         swipeToSlide: true,
  //       },
  //     },
  //     {
  //       breakpoint: 1023,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: false,
  //         nextArrow: <SampleNextArrow />,
  //         prevArrow: <SamplePreviousArrow />,
  //         swipeToSlide: true,
  //       },
  //     },
  //   ],
  // };
  return (
    <ProductSectionContainer>
      <ProductSectionWrapper>
        <ProductSectionTitle>{gift_cards_msg}</ProductSectionTitle>
        <section>
          <CardsSection>
            {/* <Slider {...settings}> */}
            {giftCards.slice(0, currentCount).map((giftCard, index) => (
              <GiftCard giftcard={giftCard} key={index} />
            ))}
            {/* </Slider> */}
          </CardsSection>
        </section>
        {giftCards.length > currentCount && (
          // <SecondaryButtonWrapper>
          <Button
            displayType="purple"
            label="Show all"
            height="48px"
            width="160px"
            fontType="bold"
            ariaLabel="Show all"
            onClick={handleShowMoreClick}
          />
          // </SecondaryButtonWrapper>
        )}
      </ProductSectionWrapper>
    </ProductSectionContainer>
  );
};

export default GiftCardsSection;
