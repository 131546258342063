import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const GiftCardContainer = styled.a`
  cursor: pointer;
  position: relative;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  // box-shadow: ${(props) => props.theme.shadows.s1};
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  border: 1px solid ${(props) => props.theme.palette.divider.d1};
  background: ${(props) => props.theme.palette.button.grey.main};
  display: grid;
  flex-grow: 1;
  max-width: calc((100% - 48px) / 4);
  min-width: calc((100% - 48px) / 4);

  height: 176px;
  color: ${(props) => props.theme.palette.text.t1};
  transition: 0.3s all ease;
  #gift-card-image {
    // transition: 0.3s all ease;
    // transform: perspective(75px) rotateY(-10deg) rotateZ(6deg) rotateX(0deg);
  }
  &:hover {
    background: ${(props) => props.theme.palette.button.grey.main};
    #gift-card-image {
      // transform: perspective(75px) rotateY(0deg) rotateZ(0deg) rotateX(0deg);
    }
  }
  &:active {
    transform: scale(0.95);
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-grow: 1;
    max-width: calc((100% - 16px) / 2);
    min-width: calc((100% - 16px) / 2);
    overflow: hidden;
    &:hover {
      background: ${(props) => props.theme.palette.background.bg1};
      #gift-card-image {
        // transform: perspective(75px) rotateY(-10deg) rotateZ(6deg) rotateX(0deg);
      }
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    min-width: 100%;
    max-width: 100%;
    flex-grow: none;
    height: 128px;
    overflow: hidden;
  }
`;

export const GiftCardShadow = styled.div`
  display: block;
  width: 110px;
  height: 18px;
  bottom: 8px;
  right: 16px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
`;

export const GiftCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const GiftGif = styled.img`
  position: absolute;
  // right: 16px;
  // top: 24px;
  height: 100%;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    right: 32px;
    height: 126px;
    width: 96px;
  }
`;

export const GiftImage = styled.img`
  position: absolute;
  right: 30px;
  top: 24px;
  height: 108px;
  width: 81px;
  transition: 0.3s all ease;

  @media (max-width: ${WIDTH.mobileMax}) {
    right: 32px;
    height: 158px;
    width: 117px;
  }
`;

export const PlatformIconContainer = styled.div``;

export const PlatformTitleContainer = styled.div`
  font: 1.25rem/1.5rem Onest-Bold;
  max-width: 50%;

  @media (max-width: ${WIDTH.mobileMax}) {
    font: 1.25rem/1.5rem Onest-Bold;
    margin-right: 10px;
  }
`;

export const BottomGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    ${(props) => props.theme.palette.background.bg1} 100%
  );
  opacity: 0.7;
`;
