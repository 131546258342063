import Image from 'next/image';
import router from 'next/router';
import React from 'react';

import { FaGlobeAmericas } from 'react-icons/fa';
import { ProductSearchInterface } from '../../Interface/ProductSearchInterface';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import { displayCurrencyValue } from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import { addToRecentSearches } from '../SearchAutocomplete/SearchAutocomplete';
import {
  BottomContainer,
  Container,
  Content,
  DetailContainer,
  PriceContainer,
  Product,
  ProductListContainer,
} from './RelatedSearchesMobileStyles';

const RelatedSearchesMobile: React.FC<{
  autocompleteResult: Array<ProductSearchInterface> | null;
  searchText: string;
}> = ({ autocompleteResult, searchText }) => {
  const { messages } = useLocale();
  const { related_results_msg, sold_out_msg } = messages || {};

  const { geoLocation, locationDetails } = useAppSelector(
    (state) => state.global
  );
  return (
    <Container>
      <h3>{related_results_msg}</h3>
      <ProductListContainer>
        {autocompleteResult?.map((product, i) => (
          <Product
            key={i}
            onClick={() => {
              router.push(`/${product.slug}`);
              saveEventV3({
                category: 'header',
                action: 'click',
                label: 'search_result_click',
                properties: product.slug,
                value: [searchText],
                from: router,
              });

              addToRecentSearches(product);
            }}
          >
            <Image
              src={
                product.image ||
                'https://static.driffle.com/fit-in/360x256/fallback_image.png'
              }
              width="87px"
              height="121px"
              alt="product"
            />
            <Content>
              <h4>
                {product.title.length > 43
                  ? product.title.slice(0, 40) + ' ...'
                  : product.title}
              </h4>
              <BottomContainer>
                <DetailContainer>
                  {exportPlatformIcon(
                    product.platform!,
                    '16px',
                    '16px',
                    '0 0 0 0'
                  )}
                  <p>{product.platform}</p>
                </DetailContainer>
                <DetailContainer>
                  <FaGlobeAmericas color={COLORS.black} fontSize={'16px'} />
                  <p>{geoLocation?.country_name}</p>
                </DetailContainer>
                <PriceContainer>
                  {product.price
                    ? displayCurrencyValue(
                        product.price,
                        locationDetails?.currency_symbol,
                        locationDetails?.multiplier
                      )
                    : sold_out_msg}
                </PriceContainer>
              </BottomContainer>
            </Content>
          </Product>
        ))}
      </ProductListContainer>
    </Container>
  );
};

export default RelatedSearchesMobile;
