import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const SearchPageContainer = styled.div`
  padding: 12px;
  max-width: 580px;
  margin: auto;
  background: ${COLORS.white};
`;

export const SearchGiftCardsSection = styled.div`
  display: flex;
  margin-top: 16px;
  //   gap: 8px;
  overflow: scroll;
  max-width: 100%;
`;
