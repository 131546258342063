import React from 'react';

import SearchPage from '../../components/MobileNav/SearchPage';
import deviceSize from '../../HOC/deviceSize';
import SiteLayout from '../../Layout/SiteLayout';
import { getUserInfoSSR } from '../../utils/cf';

const Search: React.FC = () => {
  return (
    <SiteLayout>
      <SearchPage />
    </SiteLayout>
  );
};

export default deviceSize(Search);

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
