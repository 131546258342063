import { useRouter } from 'next/router';
import React from 'react';
import { ButtonComp } from './Button.style';

interface ButtonProps {
  type?: 'submit' | 'reset' | 'button';
  displayType?: string;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large' | 'full';
  height?: string;
  width?: string;
  label: JSX.Element | string;
  onClick?: ((e: React.MouseEvent) => void) | (() => void);
  borderType?: string;
  disabled?: boolean;
  fontType?: string;
  ariaLabel: string;
}

const Button = ({
  type = 'button',
  size = 'medium',
  backgroundColor,
  fontType = 'normal',
  height,
  width,
  label,
  onClick,
  borderType,
  displayType,
  disabled,
  ariaLabel,
  ...props
}: ButtonProps) => {
  const router = useRouter();
  return (
    <ButtonComp
      type={type}
      displayType={displayType}
      fontType={fontType}
      size={size}
      height={height}
      width={width}
      onClick={disabled ? () => {} : onClick}
      backgroundColor={backgroundColor}
      borderType={borderType}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {label}
    </ButtonComp>
  );
};

export default Button;
