import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENT_GIFTCARD_CLICKED } from '../../utils/we';
import { BackgroundIcon } from '../Icons/Icons';
import { GiftCardsInterface } from '../ProductSection/GiftCardsSection';
import {
  GiftCardContainer,
  GiftCardShadow,
  GiftCardWrapper,
  GiftImage,
  PlatformIconContainer,
  PlatformTitleContainer,
} from './GiftCardStyle';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});

interface Props {
  giftcard: GiftCardsInterface;
  hideText?: boolean;
}

const GiftCard = ({ giftcard, hideText }: Props) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  return (
    <>
      <Link href={{ pathname: giftcard.url }} passHref prefetch={false}>
        <GiftCardContainer
          onClick={() => {
            WE_USER_EVENT_GIFTCARD_CLICKED(
              {
                giftCardName: giftcard.title,
                pageUrl: window.location.href,
                link: giftcard.url + giftcard.query ? '?' + giftcard.query : '',
              },
              cleverTap
            );

            saveEventV3({
              category: 'collection_card',
              action: 'click',
              label: 'giftcards',
              properties: 'collection',
              value: [giftcard.title],
              from: router,
            });
          }}
        >
          <GiftImage
            id="gift-card-image"
            src={giftcard.imageUrl}
            alt="gift card image"
          />
          <GiftCardShadow />

          <GiftCardWrapper>
            <PlatformIconContainer>
              <BackgroundIcon
                url={giftcard.iconUrl}
                height={giftcard.webSize.height}
                width={giftcard.webSize.width}
              />
            </PlatformIconContainer>
            {hideText ? null : (
              <PlatformTitleContainer>{giftcard.displayName}</PlatformTitleContainer>
            )}
          </GiftCardWrapper>
        </GiftCardContainer>
      </Link>
    </>
  );
};

export default GiftCard;
