/* eslint-disable react/display-name */
import { useRouter } from 'next/router';
import { WIDTH } from '../constants/screenResolution';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../utils/getNumberFromResolutionConstant';

const deviceSize = (WrappedComponent: any) => (props: any) => {
  const router = useRouter();
  const { width } = useWindowDimensions();

  if (typeof window !== 'undefined' && width) {
    if (width <= getNumFromResolutionConstant(WIDTH.tabletMax)) {
      return <WrappedComponent {...props} />;
    } else {
      router.push('/?search=1');
      return null;
    }
  } else {
    return null;
  }
};

export default deviceSize;
