import { Skeleton } from '@mui/material';
import router from 'next/router';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';

import Link from 'next/dist/client/link';
import Image from 'next/image';
import { FaGlobeAmericas } from 'react-icons/fa';
import { useLocale } from '../../contexts/LocalizationContext';
import { useTrendingProducts } from '../../contexts/TrendingProductsContext';
import { displayCurrencyValue } from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import {
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../../utils/localStorageUtils';
import { MaskIcon } from '../Icons/Icons';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import { addToRecentSearches } from '../SearchAutocomplete/SearchAutocomplete';
import {
  BottomContainer,
  Container,
  Content,
  DetailContainer,
  PriceContainer,
  Product,
  ProductListContainer,
  RecentResultSection,
  RecentSearchItem,
  RecentSearchItemContainer,
  TrendingSearchesSection,
} from './RecentSearchesMobileStyles';

const RecentSearchesMobile: React.FC = () => {
  const { messages } = useLocale();
  const { recent_searches_msg, trending_searches_msg, sold_out_msg } =
    messages || {};

  const { geoLocation, locationDetails } = useAppSelector(
    (state) => state.global
  );
  const [recentSearches, setRecentSearches] = useState<null | {
    [key: string]: { title: string; image: string };
  }>(null);

  const { loading, trendingProducts, fetchTrendingProducts } =
    useTrendingProducts();

  useEffect(() => {
    if (!trendingProducts) {
      fetchTrendingProducts();
    }
  }, [fetchTrendingProducts, trendingProducts]);

  const handleDeleteRecentSearch = (key: string) => {
    setRecentSearches((prev) => {
      if (prev) {
        delete prev[key as keyof Object];
        saveLocalStorageExp('recentSearches', { ...prev }, 10080);
        return {
          ...prev,
        };
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    const recentSearches: any = getLocalStorageExp('recentSearches');
    if (recentSearches) {
      setRecentSearches(JSON.parse(recentSearches));
    }
  }, []);

  return (
    <Container>
      {recentSearches && Object.keys(recentSearches).length > 0 && (
        <RecentResultSection>
          <h3>{recent_searches_msg}</h3>
          {Object.keys(recentSearches)
            .slice(0, 5)
            .map((key: string) => (
              <RecentSearchItemContainer key={key}>
                <Link passHref href={'/' + key}>
                  <a>
                    <RecentSearchItem
                    // onClick={() => {
                    //   router.push('/' + key);
                    // }}
                    >
                      <p>{recentSearches[key as keyof object].title}</p>
                    </RecentSearchItem>
                  </a>
                </Link>
                <button onClick={() => handleDeleteRecentSearch(key)}>
                  <MaskIcon
                    url="/icons/close-24.svg"
                    selected={true}
                    width="24px"
                    height="24px"
                    margin="0"
                  />
                </button>
              </RecentSearchItemContainer>
            ))}
        </RecentResultSection>
      )}
      {loading ? (
        <>
          <Skeleton height={144} width={'100%'} />
          <Skeleton height={144} width={'100%'} />
        </>
      ) : (
        trendingProducts &&
        trendingProducts.length > 0 && (
          <TrendingSearchesSection>
            <h3>{trending_searches_msg}</h3>
            <ProductListContainer>
              {trendingProducts.map((product, i) => (
                <Product
                  key={i}
                  onClick={() => {
                    router.push(`/${product.slug}`);
                    addToRecentSearches(product);
                  }}
                >
                  <Image
                    src={
                      product.image ||
                      'https://static.driffle.com/fit-in/360x256/fallback_image.png'
                    }
                    width="87px"
                    height="121px"
                    alt="product"
                  />
                  <Content>
                    <h4>
                      {product.title.length > 43
                        ? product.title.slice(0, 40) + ' ...'
                        : product.title}
                    </h4>
                    <BottomContainer>
                      <DetailContainer>
                        {exportPlatformIcon(
                          product.platform!,
                          '16px',
                          '16px',
                          '0 0 0 0'
                        )}
                        <p>{product.platform}</p>
                      </DetailContainer>
                      <DetailContainer>
                        <FaGlobeAmericas
                          color={COLORS.black}
                          fontSize={'16px'}
                        />
                        <p>{geoLocation?.country_name}</p>
                      </DetailContainer>
                      <PriceContainer>
                        {product.price
                          ? displayCurrencyValue(
                              product.price,
                              locationDetails?.currency_symbol,
                              locationDetails?.multiplier
                            )
                          : sold_out_msg}
                      </PriceContainer>
                    </BottomContainer>
                  </Content>
                </Product>
              ))}
            </ProductListContainer>
          </TrendingSearchesSection>
        )
      )}
    </Container>
  );
};

export default RecentSearchesMobile;
