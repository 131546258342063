import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Container = styled.div`
  & h3 {
    font-size: 24px;
    font-family: Onest-Bold;
    line-height: 24px;
    color: ${COLORS.black};
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const RecentResultSection = styled.section`
  border-bottom: 1px solid ${COLORS.greyLight4};
  margin-bottom: 18px;
`;

export const TrendingSearchesSection = styled.section`
  padding-bottom: 16px;
`;

export const ProductListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;
export const Product = styled.div`
  background: ${COLORS.white};
  border-radius: 8px;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.08);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  padding: 16px;
  transition: 0.3s;
  & img {
    border-radius: 8px;
  }
  & h4 {
    font-size: 18px;
    line-height: 20px;
    font-family: Onest-Bold;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }
  &:active {
    transform: scale(0.9);
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DetailContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  & > p {
    font-size: 12px;
    color: ${COLORS.gray3};
    line-height: 16.8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const PriceContainer = styled.div`
  font-size: 16px;
  fontweight: 700;
  font-family: 'Onest-Bold';
`;

export const RecentSearchItemContainer = styled.div`
  padding: 16px 0;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr auto;
  align-items: center;
  transition: 0.3s;
  & button {
    transition: 0.9s;
    &:active {
      transform: scale(0.9);
    }
  }
`;

export const RecentSearchItem = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-family: Onest-Regular;
  color: ${COLORS.black};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
